// extracted by mini-css-extract-plugin
export var benefitsCustomSoftwareSection = "L_gW";
export var businessCustomSoftwareSection = "L_gT";
export var caseStudyCustomSoftwareSection = "L_gY";
export var casesCustomSoftwareSection = "L_g0";
export var clientQuotesCustomSoftwareSection = "L_g6";
export var expertiseCustomSoftwareSection = "L_g2";
export var industriesCustomSoftwareSection = "L_gS";
export var ourAchievementsCustomSoftwareSection = "L_g5";
export var processCustomSoftwareSection = "L_gV";
export var requirementCustomSoftwareSection = "L_gZ";
export var servicesCustomSoftwareSection = "L_gR";
export var techStackCustomSoftwareSection = "L_gX";
export var testimonialsCustomSoftwareSection = "L_g3";
export var testimonialsCustomSoftwareSection__testimonial = "L_g4";
export var whyChooseCustomSoftwareSection = "L_g1";